@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./fonts/fonts.css";

@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: '1903 sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("/public/BG_BLACK.png");
  color: white;
}

h1 {
  font-weight: 800;
  font-size: x-large;
  font-family: "1903 sans condensed";
}

p {
  font-family: "1903 sans";
}

@media (max-width: 768px) {
  h1 {
    font-weight: 800;
    font-size: medium;

  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

.mask-image {
  mask-image: linear-gradient(to bottom, transparent 2%, black 25%);
  -webkit-mask-image: linear-gradient(to bottom, transparent 2%, black 25%);
}

.mask-image2 {
  mask-image: linear-gradient(to right, transparent 2%, black 90%);
  -webkit-mask-image: linear-gradient(to right, transparent 2%, black 90%);
}

.bg-orange {
  background-color: #FE9300;
}

.text-orange {
  color: #FE9300;
}

.bg-img-white {
  background-image: url('/public/BG_WHTE.png');
  background-size: 100%;
  background-position: 'center';
}

.bg-img-black {
  background-image: url('/public/BG_BLACK.png');
  background-size: 100%;
  background-position: 'center';
}

.container-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 1rem;
  grid-auto-flow: row;
  grid-template-areas:
    "event-1 event-1"
    "event-2 event-4"
    "event-3 event-4";
  width: 50rem;
}

@media (max-width: 768px) {
  .container-events {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      "event-1"
      "event-2"
      "event-3"
      "event-4";
    width: 75%;
  }
}

.event-content {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 50%;
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  transition: height 0.3s ease;
}

.event-content p {
  overflow: visible;
  white-space: normal;
  max-height: none;
}

.event-content.expanded {
  height: 100%;
}

.event-4 .event-content {
  height: 24%;
}

@media (max-width: 768px) {
  .event-4 .event-content {
    height: 50%;
  }

  .event-4 .event-content.expanded {
    height: 100%;
  }
}

.event-content button {
  transition: transform 0.3s ease;
}

.event-content button.spin {
  transform: rotate(180deg);
}

.event-content p {
  margin: 0;
  overflow: hidden;
}

.event-content:not(.expanded) p {
  max-height: 3rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.event-content p.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.white-shadow {
  box-shadow: -4px 4px 0 white;
}

.faq-answer {
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.faq-answer p {
  margin: 0;
  padding: 0;
}

.container-footer {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  gap: 0px 0px;
  margin: auto;
  grid-template-areas:
    "site-map location"
    "newsletter newsletter"
    "socials socials";
}

.site-map {
  grid-area: site-map;
  width: auto;
}

.socials {
  grid-area: socials;
}

.newsletter {
  grid-area: newsletter;
}

.location {
  grid-area: location;
  width: auto;
}

/* HTML: <div class="loader"></div> */

.loading-overlay {
  position: fixed;
  /* Ensure it covers the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 70px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #FE9300 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1.5s infinite linear;
}

@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%
  }

  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%
  }

  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%
  }

  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%
  }
}

/* HTML: <div class="loader"></div> */
.loader-text {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  color: #EDE1BE;
  font-size: 1.25rem;
  clip-path: inset(0 3ch 0 0);
  animation: l4 3s steps(4) infinite;
}

.loader-text:before {
  content: "Please Wait...";
}

@keyframes l4 {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

/* Default content for larger screens */
.offer-text::before {
  content: 'SPECIAL OFFER - LIMITED TIME ONLY:';
  display: inline;
}

/* Content for smaller screens */
@media (max-width: 768px) {
  .offer-text::before {
    content: 'LIMITED OFFER:';
  }
}

.responsive-iframe {
  width: 100%;
  max-width: 390px;
  height: 400px;
}

@media (max-width: 768px) {
  .responsive-iframe {
    width: 100%;
    max-width: 100%;
    height: 250px;
  }
}

body.no-scroll {
  overflow: hidden;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  /* For Firefox */
  appearance: textfield;
  /* For modern browsers */
}