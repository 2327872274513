/* 1903 Sans */
@font-face {
    font-family: "1903 sans";
    src: url(1903Sans-Regular.woff2) format('woff2');
    font-weight: normal;
}

@font-face {
    font-family: "1903 sans";
    src: url(1903Sans-Bold.woff2) format('woff2');
    font-weight: bold;
}

@font-face {
    font-family: "1903 sans";
    src: url(1903Sans-Italic.woff2) format('woff2');
    font-style: italic;
}

@font-face {
    font-family: "1903 sans";
    src: url(1903Sans-BoldItalic.woff2) format('woff2');
    font-weight: bold;
    font-style: italic;
}

/* 1903 Seriff */
@font-face {
    font-family: "1903 seriff";
    src: url(1903Serif-Regular.woff2) format('woff2');
    font-weight: normal;
}

@font-face {
    font-family: "1903 seriff";
    src: url(1903Serif-Bold.woff2) format('woff2');
    font-weight: bold;
}

/* 1903 Sans Condensed */
@font-face {
    font-family: "1903 sans condensed";
    src: url(1903SansCondensed-Bold.woff2) format('woff2');
    font-weight: normal;
}

.headline {
    font-family: "1903 sans condensed", sans-serif;
}

.subhead {
    font-family: "1903 sans";
    font-weight: bold;
}

.caption {
    font-family: "1903 seriff";
}